<template>
    <div class="AddBox">
        <van-nav-bar title="危险品实验申请" safe-area-inset-top   left-text="返回" left-arrow   @click-left="$router.push('/danger')"/> 
        <article-detail :isEdit='false'></article-detail>
    </div>
</template>
<script>
import ArticleDetail from './components/ArticleDetail'
import {NavBar } from 'vant';
export default {
    components:{
        [NavBar.name]: NavBar,
        ArticleDetail
    },
    data() {
        return {
            
        }
    },
    
}
</script>
<style lang="scss" scoped>
    .AddBox {
        height: 100vh;
    }
</style>